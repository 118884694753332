<template>
  <div class="umfrage-site">
    <header>
      <h1>Suche</h1>
    </header>
    <section class="survey">
      <div data-tf-live="01HZ54TM2C8BQ26P5V01191VRD"></div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'UmfrageSite',
  mounted() {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }
}
</script>

<style scoped>
.umfrage-site {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 900px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 40px;
  background: #EEECDF;
  color: black;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

h1 {
  font-size: 2.2em;
  margin: 0;
}

.survey {
  margin-top: 20px;
  color: #34495e;
  line-height: 1.8;
  background: #f8f9fa;
  border-radius: 10px;
}

.survey p {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

iframe {
  width: 100%;
  border: none;
  border-radius: 10px;
}
</style>
