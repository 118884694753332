<template>
  <nav class="navbar">
    <ul>
      <li class="logo">
        <router-link to="/"><img :src="require('@/assets/logo.png')" alt="Logo"/></router-link>
      </li>
      <li class="burger-menu" @click="toggleMenu">
        <div :class="{ 'open': isMenuOpen }">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </li>
    </ul>
    <ul v-if="isMenuOpen || !isMobile" class="menu-items">
      <li>
        <router-link to="/ueberuns">Über uns</router-link>
      </li>
      <li>
        <router-link to="/dienstleistung">Dienstleistung</router-link>
      </li>
      <li>
        <router-link to="/umfrage">Suche</router-link>
      </li>
      <li>
        <router-link to="/termine">Termine</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isMenuOpen: false,
      isMobile: false,
    };
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 800;
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #EEECDF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 0;
  flex-wrap: wrap;
}

.navbar li {
  display: inline;
}

.navbar .logo {
  margin-right: auto;
}

.navbar .logo img {
  height: 70px;
  transition: transform 0.3s;
}

.navbar .logo img:hover {
  transform: scale(1.1);
}

.navbar a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.7em;
  transition: color 0.3s;
}

.navbar a:hover {
  color: darkgreen;
}

.navbar a:active,
.navbar a:focus {
  color: black;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-menu div {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}

.burger-menu span {
  display: none;
}

.burger-menu .open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-menu .open span:nth-child(2) {
  opacity: 0;
}

.burger-menu .open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Media query for mobile devices */
@media (max-width: 800px) {
  .burger-menu {
    display: block;
  }

  .burger-menu div {
    display: flex;
  }

  .burger-menu span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: black;
    transition: transform 0.3s, background-color 0.3s;
  }

  .navbar ul {
  }

  .navbar li {
    display: block;
  }

  .navbar a {
    font-size: 1.2em;
    margin-top: 5px;
  }

  .navbar  {
    margin-bottom: 20px;
  }
}
</style>