<template>
  <div id="app">
    <nav-bar />
    <router-view />
    <footer>
      <router-link to="/impressum">Impressum</router-link>
    </footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'App',
  components: { NavBar }
}
</script>

<style>
#app {
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 20px 0;
  max-width: 900px;
  margin: 0 auto;
}

footer {
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 20px 0;
  background-color: #EEECDF;
  border-radius: 0 0 10px 10px;
}

footer a {
  color: black;
  text-decoration: none;
}

footer a:hover {
  color: orange;
}
</style>
