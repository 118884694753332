<template>
  <div class="container">
    <header>
      <img :src="logo" alt="Logo" width="300px">
      <h2>Über unsere Agentur</h2>
    </header>
    <section class="intro">
      <p>
        Sind Sie auf der Suche nach einem <span class="highlight">perfekten Zuhause</span> oder einem <span
          class="highlight">Feriendomizil</span> in der wunderschönen Region Istrien, Kroatien?
        Unsere erfahrenen Immobilienmakler sind hier, um Ihnen bei der Suche nach Ihrer Traumimmobilie zu helfen. Wir
        vertreten nur das Interesse den Käufer. Dabei handeln wir nach der Maxime, wir empfehlen nur Immobilien, die wir
        auch unseren Liebsten empfehlen würden. Der Makler, der nur an Ihrer Seite steht.
      </p>
      <span class="styled-list">
        <div>Wir sind eine Immobilienkäuferagentur ohne eine einzige Immobilie im Angebot.</div>
        <div>Wir hören erstmal von Ihnen, was Ihre Vorstellungen sind. Dazu vereinbaren Sie einen kostenlosen Onlinetermin mit uns oder füllen unsere Umfrage zur Immobilienwahl aus.</div>
        <div>Wir durchsuchen alle Agenturen und Webseiten in Kroatien. Prüfen die Angebote vorab auf eigentumsrechtliche Aspekte.</div>
        <div>Für Sie organisieren wir die Besichtigung der vorab geprüften und Ihren Wünschen entsprechenden Häuser und Wohnungen am Meer.</div>
        <div>Wir kontaktieren die Agentur oder den Eigentümer, die die Immobilie verkauft und vereinbaren mit der anderen Agentur, wie die Maklerprovision aufgeteilt wird.</div>
        <div>Sie zahlen damit nur eine Maklergebühr und haben eine größere Auswahl an Objekten.</div>
      </span>
    </section>
    <section class="image-section">
      <img :src="istra2" alt="Istria Landscape" class="istra-image">
    </section>
  </div>
</template>

<script>
import logo from '@/assets/logo.png';
import istra2 from '@/assets/images/istra2.JPG';  // Import the image

export default {
  name: "LandingPage",
  data() {
    return {
      logo,
      istra2  // Add the imported image to the data
    };
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 900px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 40px;
  background: #EEECDF;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

header img {
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
  margin: 0;
}

.intro, .contact {
  margin-bottom: 40px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

p, .contact span {
  color: #1b1a1a;
  line-height: 1.8;
  font-size: 1.1em;
  text-align: center;
}

.highlight {
  color: #3498db;
  font-weight: bold;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.image-section {
  text-align: center;
  margin-top: 20px;
}

.istra-image {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

footer {
  text-align: center;
  margin-top: 20px;
}

.styled-list {
  list-style-type: none;
  margin: 5px auto;
  padding-left: 30px;
}

.styled-list div {
  margin-bottom: 10px;
  line-height: 1.6;
  color: #1b1a1a;
  font-size: 1.1em;
  text-align: center; /* Ensures the text is centered */
  background: #EEECDF; /* Light background for each item */
  padding: 10px 15px; /* Padding for space around text */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.styled-list li:hover {
  background: #e9e9e9; /* Slightly darker background on hover */
}
</style>
