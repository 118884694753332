<template>
  <div class="container">
    <header>
      <img :src="logo" alt="Logo" width="300px">
      <h2>Willkommen bei Ihrer Käuferagentur für Immobilien in Istrien</h2>
      <p>
        Sie sind auf der Suche nach einer Immobilie auf Istrien? Haben aber selbst nicht so viel Zeit für die Suche, geschweige denn für die Besichtigungen?
        Dann sind Sie bei uns richtig.
      </p>
    </header>
    <section class="contact">
      <h2>Kontakt</h2>
      <span>
        Katarina Frankovic<br>
        E-Mail: <a href="mailto:Kfrankovic@domizil-detektiv-istrien.de">Kfrankovic@domizil-detektiv-istrien.de</a><br>
        Telefon: <a href="tel:+385992023405">+385 99 2023 405</a><br>
        Adresse: Kazali 15, 52204, Liznjan, Kroatien
      </span>
    </section>
    <section class="image-section">
      <img :src="istra1" alt="Istria Landscape" class="istra-image">
    </section>
  </div>
</template>

<script>
import logo from '@/assets/logo.png';
import istra1 from '@/assets/images/istra3.JPG';  // Import the image

export default {
  name: "LandingPage",
  data() {
    return {
      logo,
      istra1  // Add the imported image to the data
    };
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 900px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 40px;
  background: #EEECDF;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

header img {
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
  margin: 0;
}

.intro, .contact {
  margin-bottom: 40px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

p, .contact span {
  color: #1b1a1a;
  line-height: 1.8;
  font-size: 1.1em;
  text-align: center;
}

.highlight {
  color: #3498db;
  font-weight: bold;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.image-section {
  text-align: center;
  margin-top: 20px;
}

.istra-image {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

footer {
  text-align: center;
  margin-top: 20px;
}
</style>