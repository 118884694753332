<template>
  <div class="termine-site">
    <header>
      <h1>Termine</h1>
    </header>
    <section class="appointments">
      <p>
        Vereinbaren Sie einen Termin mit uns, um mehr über unsere Dienstleistungen zu erfahren und wie wir Ihnen helfen können, Ihr Traumhaus zu finden.
      </p>
      <p>
        Kontaktieren Sie uns unter <a href="mailto:Kfrankovic@domizil-detektiv-istrien.de">Kfrankovic@domizil-detektiv-istrien.de</a>, um einen Termin zu vereinbaren.
      </p>
    </section>
    <section class="image-section">
      <img :src="istra1" alt="Istria Landscape" class="istra-image">
    </section>
  </div>
</template>

<script>
import logo from '@/assets/logo.png';
import istra1 from '@/assets/images/istra1.JPG';  // Import the image

export default {
  name: "LandingPage",
  data() {
    return {
      logo,
      istra1  // Add the imported image to the data
    };
  }
}
</script>

<style scoped>
.termine-site {
  margin: 0 auto;
  padding: 40px 10px;
  max-width: 900px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 40px;
  background: #EEECDF;
  color: black;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

h1 {
  font-size: 2.2em;
  margin: 0;
}

.appointments {
  margin-top: 20px;
  color: #34495e;
  line-height: 1.8;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointments p {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.image-section {
  text-align: center;
  margin-top: 20px;
}

.istra-image {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
