// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../components/LandingPage.vue'
import AppImpressum from '../components/AppImpressum.vue'
import DienstleistungSite from "@/components/DienstleistungSite.vue"
import TermineSite from "@/components/TermineSite.vue";
import UmfrageSite from "@/components/UmfrageSite.vue";
import UeberUns from "@/components/UeberUns.vue";

const routes = [

    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/ueberuns',
        name: 'UeberUns',
        component: UeberUns
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: AppImpressum
    },
    {
        path: '/dienstleistung',
        name: 'Dienstleistung',
        component: DienstleistungSite
    },
    {
        path: '/termine',
        name: 'Termine',
        component: TermineSite
    },
    {
        path: '/umfrage',
        name: 'Umfrage',
        component: UmfrageSite
    }


]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
