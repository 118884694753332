<template>
  <div class="dienstleistung-site">
    <header>
      <h1>Unsere Dienstleistungen</h1>
    </header>
    <section class="services">
      <ul>
        <li>
          <h2>Individuelle Beratung</h2>
          <p>Bei uns stehen Ihre Wünsche im Mittelpunkt. Wir hören zu und nehmen uns Zeit, um Ihre Bedürfnisse und Vorstellungen zu verstehen.</p>
        </li>
        <li>
          <h2>Umfassende Suche</h2>
          <p>Unser Team durchsucht alle Agenturen und Webseiten in Kroatien, um die passende Immobilie für Sie zu finden.</p>
        </li>
        <li>
          <h2>Eigentumsrechtliche Prüfung</h2>
          <p>Bevor wir Ihnen ein Objekt vorschlagen, prüfen wir es sorgfältig auf eigentumsrechtliche Aspekte, um Ihnen eine sichere Investition zu garantieren.</p>
        </li>
        <li>
          <h2>Organisation von Besichtigungen</h2>
          <p>Wir organisieren Besichtigungen für die vorab geprüften und Ihren Wünschen entsprechenden Häuser und Wohnungen am Meer.</p>
        </li>
        <li>
          <h2>Verhandlung der Maklerprovision</h2>
          <p>Wir kontaktieren die Agentur, die die Immobilie verkauft, und vereinbaren mit Ihnen, wie die Maklerprovision aufgeteilt wird. So zahlen Sie nur eine Maklergebühr und haben dennoch eine größere Auswahl an Objekten. Unsere Maklerprovision beträgt 3 Prozent des notariell beglaubigten Kaufpreises inklusive der kroatischen Mehrwertsteuer von 25 Prozent.</p>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DienstleistungSite'
}
</script>

<style scoped>
.dienstleistung-site {
  margin: 0 auto;
  padding: 40px 10px;
  max-width: 900px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 40px;
  background: #EEECDF;
  color: black;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

h1 {
  font-size: 2.0em;
  margin: 0;
}

.services {
  margin-top: 20px;
  color: #34495e;
  line-height: 1.8;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 20px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

ul li h2 {
  margin: 0 0 10px;
  color: #2c3e50;
  font-size: 1.5em;
}

ul li p {
  margin: 0;
  color: #555;
}
</style>
