<template>
  <div className="impressum-container">
    <h2>Impressum</h2>
    <p>Hier finden Sie die rechtlichen Angaben zum Unternehmen.</p>
    <ul>
      <li><strong>Unternehmen:</strong> Immobilien Istrien - Domizil Detektive – Käuferagentur in Kroatien.</li>
      <li><strong>Adresse:</strong> Kazali 15, 52204, Liznjan, Kroatien</li>
      <li><strong>Telefon:</strong> <a href="tel:+385992023405">+385 99 2023 405</a></li>
      <li><strong>Email:</strong> <a href="mailto:Kfrankovic@domizil-detektiv-istrien.de">Kfrankovic@domizil-detektiv-istrien.de</a></li>
      <li><strong>Geschäftsführer:</strong> Katarina Frankovic</li>
      <li><strong>OIB:</strong> 09068750541</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppImpressum'
}
</script>

<style scoped>
.impressum-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
}

h2 {
  color: #2c3e50;
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

p {
  color: #34495e;
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
  color: #1b1a1a;
  font-size: 1em;
}

li {
  margin-bottom: 10px;
}

li strong {
  color: #2c3e50;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
